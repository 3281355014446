<template>
  <a-modal v-model="visible" title="Сообщество" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>

    <a-form-model :model="form">
      <a-form-model-item label="Ссылка на канал" required>
        <a-input  v-model="form.youtube" />
      </a-form-model-item>
      <a-form-model-item label="ID видео" required>
        <a-input  v-model="form.video" />
      </a-form-model-item>
      <a-form-model-item label="Текст кнопки на видеоплеере" required>
        <a-input  v-model="form.button_text" />
      </a-form-model-item>
      <div v-for="(value, index) in form.community_values" class="value_list" :key="value.id">
        <a-form-model-item label="Иконка" required>
          <div class="icon" :style="value.color">
            <img :src="value.icon" />
          </div>
        </a-form-model-item>
        <a-form-model-item label="Ссылка" required>
          <a-input  v-model="value.url" />
        </a-form-model-item>
        <a-form-model-item label="Статус" required>
          <a-select v-model="value.active">
            <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Позиция" required>
          <a-input  v-model="value.position" />
        </a-form-model-item>
        <a-form-model-item label="Цвет" required>
          <v-input-colorpicker v-model="value.adequateColorValue" @input="changeColor($event, index)" />
        </a-form-model-item>
        <a-form-model-item label="Название(ru)" required>
          <a-input  v-model="value.ru.name" />
        </a-form-model-item>
        <a-form-model-item label="Название(ua)" required>
          <a-input  v-model="value.ua.name" />
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import users from '@/mixins/users'

import InputColorPicker from 'vue-native-color-picker'
import statuses from '@/entities/statuses'

export default {
  name: 'MainCommunitySettings',

  components: { 'v-input-colorpicker': InputColorPicker },

  mixins: [users],

  data() {
    return {
      form: {},
      statuses,
      visible: false,
    }
  },

  computed: {
    isDisabled() {
      const form = this.form
      let isDisabled = false
      if (!form.youtube || !form.video || !form.button_text) {
        isDisabled = true
      }

      for (const index in form.community_values) {
        if (typeof form.community_values[index] !== 'object' && !typeof form.community_values[index]) {
          isDisabled = true
        }

        for (const item in form.community_values[index]) {
          if (typeof form.community_values[index][item] !== 'object' && !`${form.community_values[index][item]}`) {
            isDisabled = true
          }

          if (typeof form.community_values[index][item] === 'object') {
            for (const prop in form.community_values[index][item]) {
              if (!`${form.community_values[index][item][prop]}`) isDisabled = true
            }
          }
        }
      }

      return isDisabled
    },
  },

  methods: {
    open(record) {
      record.community_values = record.community_values.map(item => {
        item.adequateColorValue = item.color.split(' ')[1].slice(0, 7)
        return item
      })
      this.form = record
      this.visible = true
    },
    changeColor(color, index) {
      this.form.community_values[index].color = `background-color: ${color};`
    },
    async submit() {
      try {
        await this.$services.put('admin/main/community', this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.$emit('getList')
      this.fileListMain = []
      this.form = {}
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  .value_list {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 24px;
  }
  .icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    img {
      width: auto;
      height: 100%;
      max-width: 30px;
      margin: 0 auto;
      display: block;
    }
  }
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
